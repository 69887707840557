import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  ApplicationRequestedAdditionalInformation,
  RequestedDocument,
} from 'models/Candidate';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import { FormApi } from 'final-form';
import DateService from 'services/Date.service';
import UploadRequestedDocument from 'components/UploadRequestedDocument';
import InputField from 'components/InputField';
import './ApplicationUploadDocuments.styles.scss';

// Utility function to generate a hash from the question string
const generateHash = (input: string) => {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return `q_${Math.abs(hash)}`;
};

export type SubmitApplicationInformationFormValues = Record<string, File[]> & {
  videoUrl: string;
  questions: Record<string, string>;
};

type ApplicationUploadDocumentsProps = {
  className?: string;
  documents?: RequestedDocument[];
  questionsAndVideo: ApplicationRequestedAdditionalInformation;
  submitByDate: string | Date;
  handleSubmit: (
    values: SubmitApplicationInformationFormValues,
    form: FormApi<
      SubmitApplicationInformationFormValues,
      SubmitApplicationInformationFormValues
    >,
  ) => void;
};

const ApplicationUploadDocuments: React.FC<ApplicationUploadDocumentsProps> = (
  props,
) => {
  const {
    className,
    documents,
    handleSubmit,
    submitByDate,
    questionsAndVideo,
  } = props;

  const { followUpQuestions, video } = questionsAndVideo || {};
  const { t } = useTranslation();

  const classes = classNames('application-upload-documents', className);

  // Create a mapping between the hashed keys and original questions
  const questionMapping = useMemo(() => {
    return (
      followUpQuestions?.reduce((acc, { question }) => {
        const hashedQuestion = generateHash(question);
        acc[hashedQuestion] = question;
        return acc;
      }, {} as Record<string, string>) || {}
    );
  }, [followUpQuestions]);

  // Prepare initialValues by hashing the question text for both the keys and the values
  const initialValues = useMemo(() => {
    const questions = followUpQuestions
      ?.filter((question) => !!question?.answer)
      .reduce((prev, curr) => {
        const hashedQuestion = generateHash(curr.question);
        return { ...prev, [hashedQuestion]: curr.answer }; // Use hashed key
      }, {});

    const videoUrl = video?.videoUrl;

    return { questions, videoUrl };
  }, [followUpQuestions, video?.videoUrl]);

  // Handle form submission and retrieve original questions
  const onSubmit = (
    values: SubmitApplicationInformationFormValues,
    form: FormApi<
      SubmitApplicationInformationFormValues,
      SubmitApplicationInformationFormValues
    >,
  ) => {
    // Convert hashed keys back to the original questions
    const originalQuestions = Object.keys(values.questions).reduce(
      (acc, hashedKey) => {
        const originalQuestion = questionMapping[hashedKey];
        if (originalQuestion) {
          acc[originalQuestion] = values.questions[hashedKey];
        }
        return acc;
      },
      {} as Record<string, string>,
    );

    // Now you can use handleSubmit to pass these values wherever they need to go
    handleSubmit(
      {
        ...values,
        questions: originalQuestions,
      } as SubmitApplicationInformationFormValues,
      form,
    );
  };

  return (
    <div className={classes}>
      <h1>{t('Teacher.Application.Documents.requested')}</h1>
      <p>
        {t('Teacher.Application.Documents.deadline', {
          date: DateService.getUSADateFormatLong(submitByDate),
        })}
      </p>
      <Form
        onSubmit={onSubmit} // Use the custom submit handler
        initialValues={initialValues as SubmitApplicationInformationFormValues}
        render={({ handleSubmit, values, pristine }) => {
          let isButtonDisabled = false;

          const { questions, videoUrl, ...restValues } = values;

          if (!values || pristine) {
            isButtonDisabled = true;
          } else {
            if (
              Object.values(restValues).every((v) => v.length === 0) &&
              !Object.keys(questions ?? {})?.length &&
              !videoUrl
            ) {
              isButtonDisabled = true;
            }
          }

          return (
            <form onSubmit={handleSubmit}>
              <div className="application-upload-documents__list">
                {documents?.map((document) => (
                  <UploadRequestedDocument
                    document={document}
                    key={document._id}
                  />
                ))}
              </div>
              {!!followUpQuestions?.length && (
                <div className="application-upload-documents__questions">
                  <p>{t('VacancySettings.Add.questions')}</p>
                  {followUpQuestions.map(({ question, answer }) => {
                    const hashedQuestion = generateHash(question); // Hash the question
                    return (
                      <div
                        key={hashedQuestion} // Use hashed question as key
                        className="application-upload-documents__questions__question"
                      >
                        <p>{question}</p>
                        <Field
                          name={`questions.${hashedQuestion}`} // Use hashed question as field name
                          component={InputField}
                          label={t('answer')}
                          disabled={!!answer}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {!!video && (
                <div className="application-upload-documents__questions">
                  <p>{t('videoRequestedBySchool')}</p>
                  <div className="application-upload-documents__questions__question">
                    <p>{video?.videoDescription}</p>
                    <Field
                      name="videoUrl"
                      component={InputField}
                      label={t('videoUrlPlaceholder')}
                      disabled={!!video?.videoUrl}
                    />
                  </div>
                </div>
              )}
              <p>{t('UploadRequestedDocument.noteBeforeSubmit')}</p>
              <Button type="submit" disabled={isButtonDisabled}>
                {t('submitAnswers')}
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
};

export default ApplicationUploadDocuments;
