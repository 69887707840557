import React, { useEffect } from 'react';
import classNames from 'classnames';
import SpbTopActions from '../../components/SpbTopActions';
import SpbBottomActions from '../../components/SpbBottomActions';
import TPBPageContent from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageContent';
import TPBPageHeading from 'router/subrouters/Dashboard/subrouters/TeacherProfileBuilder/components/TPBPageHeading';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import FormFieldLabel from 'components/FormFieldLabel';
import ContractDurationField from 'components/ContractDurationField';

import './SpbContractInformation.styles.scss';
import './SpbContractInformation.styles.responsive.scss';
import MonthDayField from 'components/MonthDayField';
import InputField from 'components/InputField';
import {
  required,
  requiredContractDuration,
  requiredDayMonth,
} from 'validations';
import CheckboxField from 'components/CheckboxField';
import ToggleField from 'components/ToggleField';

type SpbContractInformationProps = {
  className?: string;
};

const SpbContractInformation: React.FC<SpbContractInformationProps> = (
  props,
) => {
  const { className } = props;

  const { t } = useTranslation();

  const baseClass = 'spb-contract-information';
  const classes = classNames(baseClass, className);

  // Get form state to check if isIndefinite is checked
  const { values } = useFormState();
  const isIndefinite = values.isIndefiniteContract;
  const hasProbationaryPeriod = values.isProbationaryPeriod;
  const form = useForm();

  useEffect(() => {
    // Trigger validation when `isIndefinite` changes
    form.reset(values); // Resets form with existing values to trigger revalidation
  }, [values.isIndefinite, form, values]);

  useEffect(() => {
    if (values.isIndefiniteContract === undefined) {
      form.change('isIndefiniteContract', false);
    }
  }, [values.isIndefiniteContract, form]);

  return (
    <div className={classes}>
      <SpbTopActions to="../host-country-requirements" />
      <TPBPageContent>
        <TPBPageHeading
          text={t('SchoolProfileBuilder.contractInformationTitle')}
          description={t('SchoolProfileBuilder.description')}
        />
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.startingContractDuration')}
          />
          <div className={`${baseClass}__checkbox-with-label`}>
            <FormFieldLabel
              text={t('SchoolProfileBuilder.CI.isIndefiniteContract')}
            />
            <Field
              component={CheckboxField}
              name="isIndefiniteContract"
              type="checkbox"
              isStyledDefault
            />
          </div>
          <Field
            name="startingContractDuration"
            component={ContractDurationField}
            validate={isIndefinite ? undefined : requiredContractDuration()} // Disable validation if checked
            disabled={isIndefinite} // Disable field if checked
            isIndefinite={isIndefinite}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.probationaryPeriod')}
          />
          <div className={`${baseClass}__probationary-field`}>
            <Field
              name="isProbationaryPeriod"
              component={ToggleField}
              validate={required()}
              noLabel="no"
              yesLabel="yes"
            />
            {hasProbationaryPeriod && (
              <Field
                name="probationaryPeriod"
                component={ContractDurationField}
                validate={requiredContractDuration()}
              />
            )}
          </div>
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.subsequentContractDuration')}
          />
          <Field
            name="subsequentContractDuration"
            component={ContractDurationField}
            validate={isIndefinite ? undefined : requiredContractDuration()} // Disable validation if checked
            disabled={isIndefinite} // Disable field if checked
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.contractRenewalDate')}
          />
          <Field
            name="contractRenewalDate"
            component={MonthDayField}
            validate={isIndefinite ? undefined : requiredDayMonth()} // Disable validation if checked
            disabled={isIndefinite} // Disable field if checked
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.teacherContractedWorkingDays')}
          />
          <Field
            name="teacherContractedWorkingDays"
            component={InputField}
            placeholder={t('SchoolProfileBuilder.CI.eg', { days: '120' })}
            validate={required()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.adminContractedWorkingDays')}
          />
          <Field
            name="adminContractedWorkingDays"
            component={InputField}
            placeholder={t('SchoolProfileBuilder.CI.eg', { days: '50' })}
            validate={required()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel
            text={t('SchoolProfileBuilder.CI.teacherStartDate')}
          />
          <Field
            name="teacherStartDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel text={t('SchoolProfileBuilder.CI.teacherEndDate')} />
          <Field
            name="teacherEndDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel text={t('SchoolProfileBuilder.CI.adminStartDate')} />
          <Field
            name="adminStartDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <div className={`${baseClass}__field-container`}>
          <FormFieldLabel text={t('SchoolProfileBuilder.CI.adminEndDate')} />
          <Field
            name="adminEndDate"
            component={MonthDayField}
            validate={requiredDayMonth()}
          />
        </div>
        <SpbBottomActions />
      </TPBPageContent>
    </div>
  );
};

export default SpbContractInformation;
