//functions for getting step values

import { HiringPreferences } from 'models/School';
import { SchoolProfileBuilderFormValues } from '../types';

export const convertHiringPreferencesFormValues = (
  values: SchoolProfileBuilderFormValues,
) => {
  const {
    isAcceptingSingleCandidates,
    isAcceptingSingleWithNonTeachingPartner,
    isAcceptingSingleWithTeachingPartner,
    isAcceptingMarriedWithNonTeachingPartner,
    isAcceptingMarriedWithTeachingPartner,
    isAcceptingWithChildren,
  } = values;

  return {
    isAcceptingSingleCandidates,
    isAcceptingSingleWithNonTeachingPartner,
    isAcceptingSingleWithTeachingPartner,
    isAcceptingMarriedWithNonTeachingPartner,
    isAcceptingMarriedWithTeachingPartner,
    isAcceptingWithChildren,
  };
};

export const getHiringPreferencesFromSG = (values: HiringPreferences) => {
  const {
    isAcceptingSingleCandidates,
    isAcceptingSingleWithNonTeachingPartner,
    isAcceptingSingleWithTeachingPartner,
    isAcceptingMarriedWithNonTeachingPartner,
    isAcceptingMarriedWithTeachingPartner,
    isAcceptingWithChildren,
  } = values;

  return {
    isAcceptingSingleCandidates,
    isAcceptingSingleWithNonTeachingPartner,
    isAcceptingSingleWithTeachingPartner,
    isAcceptingMarriedWithNonTeachingPartner,
    isAcceptingMarriedWithTeachingPartner,
    isAcceptingWithChildren,
  };
};

export const convertHostCountryRequirementsFormValues = (
  values: SchoolProfileBuilderFormValues,
) => {
  const {
    educationalLevelRequired,
    minimumYearsOfExperienceRequired,
    healthRequirements,
    preferredPassports,
    maximumAge,
    minimumAge,
  } = values;

  return {
    educationalLevelRequired,
    minimumYearsOfExperienceRequired,
    healthRequirements,
    preferredPassports,
    ...(maximumAge && { maximumAge: parseInt(maximumAge) }),
    ...(minimumAge && { minimumAge: parseInt(minimumAge) }),
  };
};

export const convertContactInformationFormValues = (
  values: SchoolProfileBuilderFormValues,
) => {
  const {
    startingContractDuration,
    subsequentContractDuration,
    contractRenewalDate,
    teacherContractedWorkingDays,
    adminContractedWorkingDays,
    teacherStartDate,
    teacherEndDate,
    adminStartDate,
    adminEndDate,
    isIndefiniteContract,
    probationaryPeriod,
    isProbationaryPeriod,
  } = values;

  return {
    startingContractDuration,
    subsequentContractDuration,
    contractRenewalDate,
    teacherContractedWorkingDays,
    adminContractedWorkingDays,
    teacherStartDate,
    teacherEndDate,
    adminStartDate,
    adminEndDate,
    isIndefiniteContract,
    probationaryPeriod,
    isProbationaryPeriod,
  };
};

export const convertBenefitsFormValues = (
  values: SchoolProfileBuilderFormValues,
) => {
  const {
    isTuitionForDependentsOffered,
    dependentsDevelopmentOffered,
    isTravelAllowanceOffered,
    travelAllowanceOffered,
    isFinancialSupportForRelocationOffered,
    financialSupportForRelocationOffered,
    isHousingOffered,
    housingOffered,
    isPrivateHealthInsuranceOffered,
    privateHealthInsuranceOffered,
    isPensionContributionOffered,
    pensionContributionOffered,
    isLeaveAllowanceOffered,
    leaveAllowanceOffered,
    isProfessionalDevelopmentOffered,
    professionalDevelopmentOffered,
    isAdditionalBenefitOffered,
    additionalBenefitOffered,
    studentContactDays,
    studentNonContactDays,
  } = values;

  return {
    isTuitionForDependentsOffered,
    dependentsDevelopmentOffered,
    isTravelAllowanceOffered,
    travelAllowanceOffered,
    isFinancialSupportForRelocationOffered,
    financialSupportForRelocationOffered,
    isHousingOffered,
    housingOffered,
    isPrivateHealthInsuranceOffered,
    privateHealthInsuranceOffered,
    isPensionContributionOffered,
    pensionContributionOffered,
    isLeaveAllowanceOffered,
    leaveAllowanceOffered,
    isProfessionalDevelopmentOffered,
    professionalDevelopmentOffered,
    isAdditionalBenefitOffered,
    additionalBenefitOffered,
    ...(studentContactDays && {
      studentContactDays: parseInt(studentContactDays),
    }),
    ...(studentNonContactDays && {
      studentNonContactDays: parseInt(studentNonContactDays),
    }),
  };
};

//functions for converting

export const convertFormToApi = (values: SchoolProfileBuilderFormValues) => {
  return {
    ...convertHiringPreferencesFormValues(values),
    ...convertHostCountryRequirementsFormValues(values),
    ...convertContactInformationFormValues(values),
    ...convertBenefitsFormValues(values),
  };
};

export const convertHPtoFormValues = (values: HiringPreferences) => {
  const {
    isFilled,
    filledSteps,
    studentContactDays,
    studentNonContactDays,
    maximumAge,
    minimumAge,
    ...rest
  } = values || ({} as HiringPreferences);
  return {
    ...rest,
    ...(maximumAge && { maximumAge: maximumAge.toString() }),
    ...(minimumAge && { minimumAge: minimumAge.toString() }),
    ...(studentContactDays && {
      studentContactDays: studentContactDays.toString(),
    }),
    ...(studentNonContactDays && {
      studentNonContactDays: studentNonContactDays.toString(),
    }),
  };
};
