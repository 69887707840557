import useAppSelector from 'hooks/useAppSelector';
import { useTranslation } from 'react-i18next';
import {} from 'searchality-data';
import authSelectors from 'store/selectors/auth.selectors';
import utils from 'utils';
import { ContractDurationType } from '../subrouters/SchoolProfileBuilder/types';

export default () => {
  const { schoolGroup } = useAppSelector(authSelectors.selectUser);

  const { t } = useTranslation();

  const { hiringPreferences } = schoolGroup;

  const convertUnitToTranslation = (
    contractDurcation: ContractDurationType,
  ) => {
    const { value, unit } = contractDurcation;
    if (value > 1) {
      return `${value} ${unit}`;
    }

    return `${value} ${unit.slice(0, -1)}`;
  };

  const {
    startingContractDuration,
    subsequentContractDuration,
    contractRenewalDate,
    teacherContractedWorkingDays,
    adminContractedWorkingDays,
    teacherStartDate,
    teacherEndDate,
    adminStartDate,
    adminEndDate,
    isIndefiniteContract,
    isProbationaryPeriod,
    probationaryPeriod,
  } = hiringPreferences || {};

  return [
    {
      label: t('SchoolProfileBuilder.CI.startingContractDuration'),
      value: isIndefiniteContract
        ? t('SchoolProfileBuilder.CI.isIndefiniteContract')
        : convertUnitToTranslation(startingContractDuration),
    },
    {
      label: t('SchoolProfileBuilder.CI.probationaryPeriod'),
      value: isProbationaryPeriod
        ? convertUnitToTranslation(probationaryPeriod)
        : t('no'),
    },
    {
      label: t('SchoolProfileBuilder.CI.subsequentContractDuration'),
      value: isIndefiniteContract
        ? t('noData')
        : convertUnitToTranslation(subsequentContractDuration),
    },
    {
      label: t('SchoolProfileBuilder.CI.contractRenewalDate'),
      value: isIndefiniteContract
        ? t('noData')
        : utils.convertToOrdinal(contractRenewalDate.day) +
          ' ' +
          contractRenewalDate.month,
    },
    {
      label: t('SchoolProfileBuilder.CI.teacherContractedWorkingDays'),
      value: teacherContractedWorkingDays,
    },
    {
      label: t('SchoolProfileBuilder.CI.adminContractedWorkingDays'),
      value: adminContractedWorkingDays,
    },
    {
      label: t('SchoolProfileBuilder.CI.teacherStartDate'),
      value:
        utils.convertToOrdinal(teacherStartDate.day) +
        ' ' +
        teacherStartDate.month,
    },
    {
      label: t('SchoolProfileBuilder.CI.teacherEndDate'),
      value:
        utils.convertToOrdinal(teacherEndDate.day) + ' ' + teacherEndDate.month,
    },
    {
      label: t('SchoolProfileBuilder.CI.adminStartDate'),
      value:
        utils.convertToOrdinal(adminStartDate.day) + ' ' + adminStartDate.month,
    },
    {
      label: t('SchoolProfileBuilder.CI.adminEndDate'),
      value:
        utils.convertToOrdinal(adminEndDate.day) + ' ' + adminEndDate.month,
    },
  ];
};
