import React, { useMemo } from 'react';
import classNames from 'classnames';
import JobPreviewHero from 'components/JobPreviewHero';
import JobPreviewSection from 'components/JobPreviewSection';
import env from 'env';
import { DateTime } from 'luxon';
import { Vacancy } from 'models/Vacancy';
import { Button } from 'ncoded-component-library';
import { useTranslation } from 'react-i18next';
import DateService from 'services/Date.service';
import { ReactComponent as ReturnIcon } from 'assets/images/Return.icon.svg';
import {
  ApplicantStage,
  BoardingPositionRequirementStatus,
  SchoolResidentialType,
  VacancyType,
} from 'searchality-data';
import utils, { numberPriceToCurrencyString } from 'utils';
import GoBackButton from 'components/GoBackButton';
import ContractTerms from './components/ContractTerms';
import pdfImage from 'assets/images/pdf-image.webp';
import JobPreviewBoardingSection from './components/JobPreviewBoardingSection';
import { ContractDurationType } from 'router/subrouters/Dashboard/subrouters/MyAccount/subrouters/HiringPreferences/subrouters/SchoolProfileBuilder/types';

import './JobPreviewComponent.styles.scss';
import './JobPreviewComponent.responsive.styles.scss';

type JobPreviewComponentProps = {
  className?: string;
  vacancy: Vacancy;
  previousPage?: () => void;
  previousPageButtonText?: string;
  noActions?: boolean;
  showGoBack?: boolean;
  noPreviousButton?: boolean;
};

const convertUnitToTranslation = (contractDurcation: ContractDurationType) => {
  const { value, unit } = contractDurcation;
  if (value > 1) {
    return `${value} ${unit}`;
  }

  return `${value} ${unit.slice(0, -1)}`;
};

const JobPreviewComponent: React.FC<JobPreviewComponentProps> = (props) => {
  const {
    className,
    previousPage,
    vacancy,
    noActions = false,
    showGoBack,
    noPreviousButton = false,
    previousPageButtonText,
  } = props;

  const { t } = useTranslation();

  const baseClass = 'job-preview';
  const classes = classNames(baseClass, className);

  const jobPreviewHeroData = useMemo(() => {
    if (vacancy) {
      const { positionTitle, isFullTimeEmployment, schools, _id, status } =
        vacancy;
      return {
        positionTitle,
        isFullTimeEmployment,
        schools,
        _id,
        status,
      };
    }
  }, [vacancy]);

  const startDateJobApplication = useMemo(() => {
    if (vacancy?.startDate === undefined) return undefined;

    if (
      DateService.toDateString(new Date(vacancy?.startDate)) ===
      DateService.toDateString(new Date(0))
    ) {
      return t('immediately');
    }

    const { monthLong, day, year } = DateTime.fromJSDate(
      new Date(vacancy?.startDate),
    );

    const startDate = `${monthLong} ${day}, ${year}`;
    return startDate;
  }, [t, vacancy?.startDate]);

  const salaryInfo = useMemo(() => {
    const salaryInfo = vacancy?.salaryInfo;
    const { from, to, applicantStage, currency } = salaryInfo || {};
    if (applicantStage !== ApplicantStage.IN_THE_JOB_POSTING) {
      return t(applicantStage);
    }

    const currencySign = currency?.split(' - ');

    return `${numberPriceToCurrencyString(
      from,
      currencySign?.[0],
    )} - ${numberPriceToCurrencyString(to, currencySign?.[0])}`;
  }, [t, vacancy?.salaryInfo]);

  const hasBenefits = useMemo(() => {
    const {
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
      isHousingOffered,
      isTravelAllowanceOffered,
      isLeaveAllowanceOffered,
    } = vacancy || {};

    return [
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
      isHousingOffered,
      isTravelAllowanceOffered,
      isLeaveAllowanceOffered,
    ].some((el) => el);
  }, [vacancy]);

  const benefits = useMemo(() => {
    if (!hasBenefits) return null;

    const {
      isPrivateHealthInsuranceOffered,
      isPensionContributionOffered,
      isProfessionalDevelopmentOffered,
      isFinancialSupportForRelocationOffered,
      isAdditionalBenefitOffered,
      isTuitionForDependentsOffered,
      isHousingOffered,
      isTravelAllowanceOffered,
      isLeaveAllowanceOffered,
      privateHealthInsuranceOffered,
      pensionContributionOffered,
      financialSupportForRelocationOffered,
      professionalDevelopmentOffered,
      additionalBenefitOffered,
      dependentsDevelopmentOffered,
      housingOffered,
      travelAllowanceOffered,
      leaveAllowanceOffered,
    } = vacancy;

    return (
      <li>
        <b> {t('jobPreview.benefits')}</b>
        <ul>
          {isPrivateHealthInsuranceOffered && (
            <li>
              {t('jobPreview.healthcareInsurance', {
                option: privateHealthInsuranceOffered,
              })}
            </li>
          )}
          {isPensionContributionOffered && (
            <li>
              {t('jobPreview.pensionContribution', {
                option: pensionContributionOffered,
              })}
            </li>
          )}
          {isFinancialSupportForRelocationOffered && (
            <li>
              {t('jobPreview.relocationStipend', {
                option: financialSupportForRelocationOffered,
              })}
            </li>
          )}
          {isProfessionalDevelopmentOffered && (
            <li>
              {t('jobPreview.professionalDevelopment', {
                developmentOptions: professionalDevelopmentOffered,
              })}
            </li>
          )}
          {isAdditionalBenefitOffered && (
            <li>
              {t('jobPreview.aditionalBenefits', {
                additionalBenefits: additionalBenefitOffered,
              })}
            </li>
          )}

          {isTuitionForDependentsOffered && (
            <li>
              {t('jobPreview.dependents', {
                options: dependentsDevelopmentOffered,
              })}
            </li>
          )}
          {isHousingOffered && (
            <li>
              {t('jobPreview.housing', {
                options: housingOffered,
              })}
            </li>
          )}
          {isTravelAllowanceOffered && (
            <li>
              {t('jobPreview.travel', {
                options: travelAllowanceOffered,
              })}
            </li>
          )}
          {isLeaveAllowanceOffered && (
            <li>
              {t('jobPreview.leave', {
                options: leaveAllowanceOffered,
              })}
            </li>
          )}
        </ul>
      </li>
    );
  }, [hasBenefits, t, vacancy]);

  const shouldBeRelativePath = pdfImage.includes('static/media');

  const pdfImagePath = shouldBeRelativePath
    ? pdfImage
    : `${env.WIDGET_ENDPOINT}${pdfImage}`;

  const curriculumExperience = useMemo(() => {
    if (vacancy?.type !== VacancyType.ACADEMIC) {
      return undefined;
    }

    if (!vacancy?.isSpecificCurriculumExperienceRequired) {
      return (
        <li>
          <b>{t('jobPreview.teachingSystem')}</b>
          {t('noExperienceNeeded')}
        </li>
      );
    }

    if (vacancy?.curricula?.length < 2) {
      return vacancy?.curricula?.map(
        ({ curriculumExperience, yearsOfCurriculumExperienceRequired }) => (
          <>
            <li>
              <b>{t('jobPreview.teachingSystem')}</b>
              {curriculumExperience}
            </li>
            <li>
              <b>
                {t('jobPreview.minimumCurriculumExperience', {
                  curriculumExperience,
                })}
              </b>
              {t('jobPreview.minimumExperienceRequired', {
                count: yearsOfCurriculumExperienceRequired,
              })}
            </li>
          </>
        ),
      );
    }

    return (
      <li>
        <b>{t('jobPreview.teachingSystem')}</b>
        <ul>
          {vacancy?.curricula?.map(
            ({ curriculumExperience, yearsOfCurriculumExperienceRequired }) => (
              <li key={curriculumExperience}>
                {curriculumExperience}, {t('minimum')}{' '}
                {t('jobPreview.minimumExperienceRequired', {
                  count: yearsOfCurriculumExperienceRequired,
                })}
              </li>
            ),
          )}
        </ul>
      </li>
    );
  }, [
    t,
    vacancy?.curricula,
    vacancy?.isSpecificCurriculumExperienceRequired,
    vacancy?.type,
  ]);

  const languages = useMemo(() => {
    if (vacancy?.requiredLanguages?.length < 2) {
      return vacancy?.requiredLanguages?.map(({ language, level }) => (
        <li key={language}>
          <b>{t('jobPreview.language')}</b>
          {`${language} / ${level}`}
        </li>
      ));
    }

    return (
      <li>
        <b>{t('jobPreview.languages')}</b>
        <ul>
          {vacancy?.requiredLanguages?.map(({ language, level }) => (
            <li key={language}>{`${language} / ${level}`}</li>
          ))}
        </ul>
      </li>
    );
  }, [t, vacancy?.requiredLanguages]);

  return (
    <div className={classes}>
      {showGoBack && <GoBackButton />}

      <JobPreviewHero
        data={jobPreviewHeroData}
        previousPageButtonText={previousPageButtonText}
        previousPage={previousPage}
      />
      <div className={`${baseClass}__section-container`}>
        <JobPreviewSection sectionNumber={1} title={t('aboutOurPosition')}>
          <div
            dangerouslySetInnerHTML={utils.createMarkup(
              vacancy?.roleDescription,
            )}
            className="role-description-container"
          />
          <p className={`${baseClass}__section-container__section-subtitle`}>
            {`${t('vacancyInformation')}: `}
          </p>
          {startDateJobApplication && (
            <ul>
              <li>
                <b>{t('jobPreview.startDate')}</b>
                {startDateJobApplication}
              </li>
            </ul>
          )}
          <ul>
            <li>
              <b>{t('jobPreview.employmentType')}</b>
              {vacancy?.isFullTimeEmployment
                ? t('fullTime')
                : t('jobPreview.partTime', {
                    details: vacancy?.amountOfTimeRequired
                      ? `, ${vacancy?.amountOfTimeRequired}`
                      : '',
                  })}
            </li>
            <li>
              <b>{t('jobPreview.contractType')}</b>

              {vacancy?.isPermanentContract
                ? t('jobPreview.permanent')
                : t('jobPreview.temporary', {
                    details: vacancy?.lengthOfContract
                      ? `, ${vacancy?.lengthOfContract}`
                      : '',
                  })}
            </li>
            <li>
              <b>{t('jobPreview.contractTerms')}</b>
              <ul>
                <li>
                  {vacancy.isIndefiniteContract
                    ? t('SchoolProfileBuilder.CI.isIndefiniteContract')
                    : t('jobPreview.cre', {
                        date: `${utils.convertToOrdinal(
                          vacancy?.contractRenewalDate?.day,
                        )} ${vacancy?.contractRenewalDate?.month}`,
                      })}
                </li>
                <li>
                  {t('jobPreview.pp', {
                    period: vacancy.isProbationaryPeriod
                      ? convertUnitToTranslation(vacancy.probationaryPeriod)
                      : t('no'),
                  })}
                </li>
                <li>
                  {t('jobPreview.sd', {
                    date: `${utils.convertToOrdinal(
                      vacancy?.teacherStartDate?.day ??
                        vacancy?.adminStartDate?.day,
                    )} ${
                      vacancy?.teacherStartDate?.month ??
                      vacancy?.adminStartDate?.month
                    }`,
                  })}
                </li>
                <li>
                  {t('jobPreview.ld', {
                    date: `${utils.convertToOrdinal(
                      vacancy?.teacherEndDate?.day ??
                        vacancy?.adminEndDate?.day,
                    )} ${
                      vacancy?.teacherEndDate?.month ??
                      vacancy?.adminEndDate?.month
                    }`,
                  })}
                </li>
                <li>
                  {t('jobPreview.cd', {
                    number:
                      vacancy?.teacherContractedWorkingDays ??
                      vacancy?.adminContractedWorkingDays,
                  })}
                </li>
              </ul>
            </li>
          </ul>
          <p className={`${baseClass}__section-container__section-subtitle`}>
            {vacancy?.schools?.length > 1
              ? t('jobPreview.locationsSubtitle')
              : t('jobPreview.locationSubtitle')}
          </p>
          <ul>
            {vacancy?.schools?.map(({ _id, name, city, country }) => (
              <li key={_id}>{`${name}, ${city}, ${country}`}</li>
            ))}
          </ul>
          {vacancy?.positionDescriptionUrl && (
            <div className={`${baseClass}__detailed-container`}>
              <p>{t('jobPreview.viewFullDescription')}</p>
              <a
                href={vacancy.positionDescriptionUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img src={pdfImagePath} alt="pdf" />
                {t('detailedPositionDescription')}
              </a>
            </div>
          )}
        </JobPreviewSection>
        <JobPreviewSection sectionNumber={2} title={t('requirements')}>
          <ul>
            {vacancy?.educationLevel && (
              <li>
                <b>{t('jobPreview.minimumDiplomaRequired')}</b>
                {vacancy?.educationLevel}
              </li>
            )}
            {!!vacancy?.certificates?.length && (
              <li>
                <b>{t('educationCertificates')}</b>
                <ul>
                  {vacancy.certificates.map((certificate, index) => (
                    <li key={index}>{certificate}</li>
                  ))}
                </ul>
              </li>
            )}
            <li>
              <b>{t('jobPreview.minimumExperience')}</b>
              {vacancy?.yearsOfExperienceRequired > 0
                ? t('jobPreview.minimumExperienceRequired', {
                    count: vacancy?.yearsOfExperienceRequired,
                  })
                : t('noExperienceRequired')}
            </li>
            {curriculumExperience}
            {languages}
          </ul>
          <p className={`${baseClass}__section-container__section-subtitle`}>
            {vacancy?.isRightToWorkRequired
              ? t('rightToWorkIn', {
                  country: vacancy?.schools?.[0]?.country,
                })
              : t('relocation')}
          </p>
          <ul>
            <li>
              {vacancy?.isRightToWorkRequired
                ? t('rightToWorkDescription', {
                    country: vacancy?.schools?.[0]?.country,
                  })
                : t('relocationDescription', {
                    country: vacancy?.schools?.[0]?.country,
                  })}
            </li>
          </ul>
        </JobPreviewSection>
        <JobPreviewSection sectionNumber={3} title={t('whatWeOffer')}>
          <ul>
            <li>
              <b>{t('jobPreview.salary')}</b>
              {vacancy?.salaryInfo?.applicantStage !==
              ApplicantStage.IN_THE_JOB_POSTING
                ? t('jobPreview.salaryDescription', { salaryInfo })
                : salaryInfo}
            </li>
            <ContractTerms
              studentContractDays={vacancy?.studentContactDays}
              studentNonContractDays={vacancy?.studentNonContactDays}
            />
            {benefits}
          </ul>
        </JobPreviewSection>
        {vacancy?.residentialType !== SchoolResidentialType.DAY_SCHOOL &&
          vacancy?.boardingPositionRequirementStatus !==
            BoardingPositionRequirementStatus.NOT_REQUIRED && (
            <JobPreviewSection sectionNumber={4} title={t('boardingParent')}>
              <JobPreviewBoardingSection
                additionalBenefits={
                  vacancy?.boardingPositionBenefits?.additionalBenefits
                }
                applicantStage={vacancy?.salaryInfo?.applicantStage}
                salary={vacancy?.boardingPositionBenefits?.salary}
                isRequired={
                  vacancy?.boardingPositionRequirementStatus ===
                  BoardingPositionRequirementStatus.REQUIRED
                }
                roleDescription={
                  vacancy?.boardingPositionRequirements?.roleDescription
                }
                roleDescriptionUrl={vacancy?.boardingPositionRequirementsUrl}
                currency={vacancy?.salaryInfo?.currency.split(' - ')?.[0]}
              />
            </JobPreviewSection>
          )}
        {!noActions && (
          <div className={`${baseClass}__button-container`}>
            <Button
              onClick={() => {
                window.open(
                  `${env.PLATFORM_ENDPOINT}/job-application/${vacancy._id}`,
                  '_blank',
                );
              }}
            >
              {t('applyNow')}
            </Button>
            {!noPreviousButton && (
              <Button
                variant="outline"
                icon={<ReturnIcon />}
                onClick={previousPage}
              >
                {previousPageButtonText}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobPreviewComponent;
